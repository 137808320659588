@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800;900&display=swap');
@import "./data/styles.css";

/* body {
	margin: 0;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
 */


html,body {
  font-family: 'Outfit', sans-serif;
  margin: 0;
  padding: 0;

}

html{
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
	width: 7px;
	background-color: #fff;
}

body::-webkit-scrollbar-thumb {
	background-color: #ccc;
}

#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 1202px) {
  .fixed-size-container {
    width: 100% !important;
    max-width: 95% !important;
    margin: 0 auto !important;
    margin-top: 30px;
  }
}

@media (min-width: 1202px) {
  .fixed-size-container {
    width: 97% !important;
    max-width: 1202px !important;
    margin: 0 auto !important;


  }
}

.agency-tc ::-webkit-scrollbar {
  width: 7px;
  background-color: #e4e4e4;
  border-radius: 10px;
}

.agency-tc ::-webkit-scrollbar-thumb {
  background: rgb(33, 150, 243);
  border-radius: 10px;
}


