.deskLogo {
	margin-left: 130px;
	margin-right: 10px;
  cursor: pointer;
}
.mobileLogo {
	display: none;
}
a {
	text-decoration: none;
}
@media only screen and (max-width: 960px) {
	.deskLogo {
		margin-left: 0px;
		display: none;
	}
	.mobileLogo {
		display: flex;
		margin-right: 10px;
	}
}
