@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 510px;
}

.homepage-image-wrapper {
	overflow: hidden;
	border-radius: 10%;
	height: 350px;
	position: relative;
	right: -25px;
	top: 25px;
}

.homepage-image-wrapper img {
	object-fit: contain;
	overflow: hidden;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
	text-align: justify;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}
.mainPhoto {
	width: 400px;
	height: 400px;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 220 220' width='100%25' height='100%25' preserveAspectRatio='none'><defs><linearGradient id='gradient'><stop offset='0' style='stop-color:%230070d8' /><stop offset='0.5' style='stop-color:%232cdbf1' /><stop offset='1' style='stop-color:%2383eb8a' /></linearGradient></defs><ellipse ry='100' rx='100' cy='110' cx='110' style='fill:none;stroke:url(%23gradient);stroke-width:6;' /></svg>");
	background-size: 100% 100%; /* Fix for Fifefox image scaling */
}

.bounceBtn {
	animation: bounce 0.5s 2 alternate;
}

@keyframes bounce {
	to {
		transform: scale(1.2);
	}
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	@media (max-width: 960px) {
		.homepage-first-area-right-side {
			align-items: center;
			justify-content: center;
		}
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}

	.homepage-image-wrapper {
		height: 215px;
		position: relative;
		right: -17px;
		top: 18px;
	}

	.mainPhoto {
		width: 250px;
		height: 250px;
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 220 220' width='100%25' height='100%25' preserveAspectRatio='none'><defs><linearGradient id='gradient'><stop offset='0' style='stop-color:%230070d8' /><stop offset='0.5' style='stop-color:%232cdbf1' /><stop offset='1' style='stop-color:%2383eb8a' /></linearGradient></defs><ellipse ry='100' rx='100' cy='110' cx='110' style='fill:none;stroke:url(%23gradient);stroke-width:6;' /></svg>");
		background-size: 100% 100%; /* Fix for Fifefox image scaling */
	}
}
